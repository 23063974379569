<template>
  <div class="wraper page_sys_dictionary flexCloumnSingleCenter">
    <el-row style="width: 100%;">
      <el-button icon="el-icon-plus" size="small" type="primary"
                 @click="handleModify(false)">
        新增</el-button>
      <el-button v-if="tableData&& tableData.length>0" size="small" type="primary"
                 @click="termConfig(54)" style="margin-left: 20px;">
        学期设置</el-button>
      <el-button v-if="tableData&& tableData.length>0" size="small" type="primary"
                 @click="baseConfig(55)" style="margin-left: 20px;">
        app基础配置</el-button>
      <el-button v-if="tableData&& tableData.length>0" size="small" type="primary"
                 @click="imgConfig(53)" style="margin-left: 20px;">
        app图片外观配置</el-button>

    </el-row>
    <div class="flex_1" style="width: 100%;margin-top: 10px;">
      <div class="" style="width: 100%;height: 100%;overflow: hidden">
        <el-table :data="tableData" height="100%" style="width: 100%;height:calc( 100% - 20px );" row-key="id" border
                  stripe default-expand-all>
          <el-table-column type="index" label="序号" width="60px"></el-table-column>
          <el-table-column prop="key_type" label="类型"></el-table-column>
          <el-table-column prop="remark" label="描述"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-tag @click="handleModify(scope.row)"  size="mini" style="cursor: pointer;">修改</el-tag>
              <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                             icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)"
                             placement="left" title="是否确定删除？">
                <a slot="reference" size="small"
                   style="color: red;font-size: 12px;margin-left: 20px; cursor: pointer;">删除</a>
              </el-popconfirm>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog custom-class="cus_dialog" width="1200px" :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form v-if="modalTitle == '添加'||modalTitle == '修改'" class="flex_1" :model="formData" ref="ruleForm" label-width="150px">

        <el-form-item label="类型">
          <el-input v-model.trim="formData.key_type" style="width: 50%" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="formData.remark" style="width: 50%" />
        </el-form-item>

        <div class="">
          字典值:
        </div>

        <div class="flexBetween" v-for="(item,index) in json_Arr">
          <div class="flexStart">
            <el-form-item :label="'选项键名（显示）'+parseInt( index + 1)">
              <el-input v-model.trim="item.name"/>
            </el-form-item>
            <el-form-item label="选项值（绑定）">
              <el-input v-model.trim="item.value" />
            </el-form-item>
            <el-form-item label="排序">
              <el-input-number :min="0" v-model.trim="item.sort" placeholder="数字"
                               controls-position="right" />
            </el-form-item>
          </div>

          <el-button icon="el-icon-delete" size="small" type="danger" round @click="del(index)" style="margin-bottom: 20px;margin-left: 20px;"></el-button>
        </div>
        <el-divider content-position="left">
          <el-button icon="el-icon-plus" size="small" type="primary" round @click="add"></el-button>
        </el-divider>
      </el-form>
      <el-form v-if="modalTitle == 'app图片外观配置'" class="flex_1" ref="ruleForm" label-width="80px">

        <el-row v-for="(item,idx) in json_Arr" :key="idx" class="flexStart sch_configBox" style="border-bottom: 1px solid #efefef;margin: 20px 0;">

          <el-col :span="5">
            <el-form-item label="参数名称">
              <el-input v-model.trim="item.name" style="width: 100%" placeholder="参数名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="参数代码">
              <el-input v-model.trim="item.field" style="width: 100%" placeholder="字段名"/>
            </el-form-item>
          </el-col>


          <el-col :span="10" class="flexStart">
            <el-form-item label="参数值" prop="role_name" style="width: 100%;">
              <div class="flexStart" style="width: 100%;">
                <el-input v-model.trim="item.value" style="width: 100%" placeholder="参数值"/>

                <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss" :on-success="uploadFiles"
                           :show-file-list="false" name="image">
                  <el-button title="上传图片" type="primary" icon="el-icon-picture-outline-round" @click="setRowIndx(idx)" circle
                             class="flexCenter" style="margin-left: 20px;font-size: 20px;padding: 8px;"></el-button>
                </el-upload>
              </div>

            </el-form-item>

          </el-col>
          <el-col :span="2">
            <div class="thumbnail flexCenter" style="width: 100%;height: 40px;border: 1px dashed #ccc;margin-bottom: 20px;">
              <img v-if="item.value" :src="item.value" @click="handlePictureCardPreview(item.value)" alt="" style="max-width: 100%;max-height: 100%;cursor: pointer;">
            </div>
          </el-col>
          <el-col :span="2">
            <el-form-item label-width="0" style="width: calc( 100% - 20px );margin-left: 20px;">
            <el-input :min="0" v-model.trim="item.sort" placeholder="排序"/>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider content-position="left">
          <el-button icon="el-icon-plus" size="small" type="primary" plain @click="add">添加新项</el-button>
        </el-divider>
      </el-form>
      <el-form v-if="modalTitle == '学期设置'" class="flex_1" ref="ruleForm" label-width="80px">

        <el-row v-for="(item,idx) in json_Arr" :key="idx" class="flexStart sch_configBox" style="border-bottom: 1px solid #efefef;margin: 20px 0;">
            <el-form-item :label="item.name">
              <el-date-picker
                  v-model="item.value"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
        </el-row>
      </el-form>
      <el-form v-if="modalTitle == 'app基础配置'" class="flex_1" ref="ruleForm" label-width="80px">

        <el-row v-for="(item,idx) in json_Arr" :key="idx" class="flexStart sch_configBox" style="border-bottom: 1px solid #efefef;margin: 20px 0;">

          <el-col :span="5">
            <el-form-item label="参数名称">
              <el-input v-model.trim="item.name" style="width: 100%" placeholder="参数名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="参数代码">
              <el-input v-model.trim="item.field" style="width: 100%" placeholder="字段名"/>
            </el-form-item>
          </el-col>


          <el-col :span="10" class="flexStart">
            <el-form-item label="参数值" prop="role_name" style="width: 100%;">
              <div class="flexStart" style="width: 100%;">
                <el-input v-model.trim="item.value" style="width: 100%" placeholder="参数值"/>

                <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss" :on-success="uploadFiles"
                           :show-file-list="false" name="image">
                  <el-button title="上传图片" type="primary" icon="el-icon-picture-outline-round" @click="setRowIndx(idx)" circle
                             class="flexCenter" style="margin-left: 20px;font-size: 20px;padding: 8px;"></el-button>
                </el-upload>
              </div>

            </el-form-item>

          </el-col>
          <el-col :span="2">
            <div class="thumbnail flexCenter" style="width: 100%;height: 40px;border: 1px dashed #ccc;margin-bottom: 20px;">
              <img v-if="item.value" :src="item.value" @click="handlePictureCardPreview(item.value)" alt="" style="max-width: 100%;max-height: 100%;cursor: pointer;">
            </div>
          </el-col>
          <el-col :span="2">
            <el-form-item label-width="0" style="width: calc( 100% - 20px );margin-left: 20px;">
              <el-input :min="0" v-model.trim="item.sort" placeholder="排序"/>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider content-position="left">
          <el-button icon="el-icon-plus" size="small" type="primary" plain @click="add">添加新项</el-button>
        </el-divider>
      </el-form>

      <div slot="footer" class="flexEnd">
          <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </div>
    </el-dialog>
    <!--预览图片-->
    <el-dialog width="400px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/api';

export default {
  name: 'sys_dictionary',
  data() {
    return {
      visible: false,
      isShowModal: false,
      modalTitle: '添加',
      formData: {},

      tableData: [],

      json_Arr:[{name:'',value:'',field:''}],
      rowidx:-1,
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
    }
  },
  watch: {

  },
  mounted() {
    this.getList();
  },
  methods: {
    add(){
        this.json_Arr.push({name:'',value:'',field:''})
    },
    del(idx){
      this.json_Arr.splice(idx,1)
    },

    getList() {
      this.$http.post("/api/sys_dictionary_list").then(res => {
        console.log(res.data)
        this.tableData = res.data
      })
    },

    handleModify(row) {
      this.isShowModal = true;
      this.modalTitle = row?'修改':'添加';
      this.formData = row?row:{}
      if(row.key_values){
        this.json_Arr = JSON.parse(row.key_values)
      }else {
        this.json_Arr =[{name:'',value:''}]
      }
    },
    handleDel(row) {
      this.$http.post("/api/sys_dictionary_delete", {
        id: row.id
      }).then(res => {
        this.$message.success('删除成功')
        this.getList();
      })
    },

    setRowIndx(idx){
      this.rowidx = idx
    },
    uploadFiles(e){
      console.log(e)
      console.log(this.rowidx)
      this.json_Arr[this.rowidx].value = e.src
    },
    submitForm(formName) {
      let _this = this

      let params = {..._this.formData}
      this.json_Arr.map(e=>{e.id=e.value})
      params.sort = 1
      if (this.json_Arr && this.json_Arr.length > 0) {
        this.json_Arr.sort((a, b) => {
          return a.sort - b.sort
        })//升
        params.key_values = JSON.stringify(this.json_Arr)
      } else {
        params.key_values = JSON.stringify([])
      }
      this.$http.post("/api/sys_dictionary_edit", params).then(res => {
        this.$message.success('保存成功')
        this.getList();
        this.isShowModal = false;

      })
    },
    imgConfig(id){
      let arr = JSON.parse(JSON.stringify(this.tableData))
      let row = {}
      arr.map(a => {
        if (a.id == id) {
          row = a
        }
      })
      if(row){
        console.log(id,row)
        this.modalTitle = 'app图片外观配置'
        this.isShowModal = true;
        this.formData = row
        this.formData.sort = 999
        if(row.key_values){
          this.json_Arr = JSON.parse(row.key_values)
        }else {
          this.json_Arr =[{name:'',value:'',field:''}]
        }
      }else {
        this.$message.warning('请联系开发者配置~')
      }
    },
    baseConfig(id){
      let arr = JSON.parse(JSON.stringify(this.tableData))
      let row = {}
      arr.map(a => {
        if (a.id == id) {
          row = a
        }
      })
      if(row){
        console.log(id,row)
        this.modalTitle = 'app基础配置'
        this.isShowModal = true;
        this.formData = row
        this.formData.sort = 999
        if(row.key_values){
          this.json_Arr = JSON.parse(row.key_values)
        }else {
          this.json_Arr =[{name:'',value:'',field:''}]
        }
      }else {
        this.$message.warning('请联系开发者配置~')
      }
    },
    handlePictureCardPreview(value) {
      let arr = value.split('.')
      let suffix = arr[arr.length - 1].toLocaleLowerCase()
      const imglist = ['svgz', 'pjp', 'png', 'ico', 'avif', 'tiff', 'tif', 'jfif', 'svg', 'xbm', 'pjpeg', 'webp', 'jpg', 'jpeg', 'bmp', 'gif'];
      let result = imglist.find(item => suffix.indexOf(item) > -1);
      if (result) {
        this.dialogImageUrl = value;
        this.dialogVisibleImageUrl = true;
      }
    },
    termConfig(id){
      let arr = JSON.parse(JSON.stringify(this.tableData))
      let row = {}
      arr.map(a => {
        if (a.id == id) {
          row = a
        }
      })
      if(row){
        console.log(id,row)
        this.modalTitle = '学期设置'
        this.isShowModal = true;
        this.formData = row
        this.formData.sort = 999
        if(row.key_values&&row.key_values!='[]'){
          this.json_Arr = JSON.parse(row.key_values)
        }else {
          this.json_Arr =[{name:'上学期',value:['2021-02-04','2022-06-30'],field:'term1'},{name:'下学期',value:['2022-09-01','2023-01-09'],field:'term2'}]
        }
      }else {
        this.$message.warning('请联系开发者配置~')
      }
    },
  }
}
</script>

<style scoped lang="less" type="text/less">
.page_sys_dictionary{
  .sch_configBox{
    .el-form-item{
      //margin: 0;
    }
  }
}
</style>
